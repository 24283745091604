import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import vSelect from "vue-select";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import VueApexCharts from "vue-apexcharts";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import JsonExcel from "vue-json-excel";
import VueDjGantt from "vue-dj-gantt";
import {
  BTabs,
  BTab,
  BSpinner,
  BCard,
  BCardText,
  BCardTitle,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,
  BFormCheckboxGroup,
  BModal,
  VBModal,
  BCardBody,
  BCardFooter,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BAvatar,
  BAvatarGroup,
  BBadge,
  BTable,
  BPagination,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BEmbed,
  BFormValidFeedback,
  BInputGroupPrepend,
  BFormInvalidFeedback,
  BProgress,
} from "bootstrap-vue";

Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component("appTimeline", AppTimeline);
Vue.component("appTimelineItem", AppTimelineItem);
Vue.component("vSelect", vSelect);
Vue.component("lMap", LMap);
Vue.component("lTileLayer", LTileLayer);
Vue.component("lMarker", LMarker);
Vue.component("lPopup", LPopup);
Vue.component("BProgress", BProgress);
Vue.component("apexchart", VueApexCharts);
Vue.component("BCardActions", BCardActions);
Vue.component("BTabs", BTabs);
Vue.component("BTab", BTab);
Vue.component("BSpinner", BSpinner);
Vue.component("downloadExcel", JsonExcel);
Vue.component("VueDjGantt", VueDjGantt);
Vue.component("flatPickr", flatPickr);
Vue.component("BCardTitle", BCardTitle);
Vue.component("BFormValidFeedback", BFormValidFeedback);
Vue.component("BInputGroupPrepend", BInputGroupPrepend);
Vue.component("BFormInvalidFeedback", BFormInvalidFeedback);
Vue.component("BEmbed", BEmbed);
Vue.component("BMedia", BMedia);
Vue.component("BMediaAside", BMediaAside);
Vue.component("BMediaBody", BMediaBody);
Vue.component("BImg", BImg);
Vue.component("BTable", BTable);
Vue.component("BPagination", BPagination);
Vue.component("BBadge", BBadge);
Vue.component("BListGroup", BListGroup);
Vue.component("BListGroupItem", BListGroupItem);
Vue.component("BAvatar", BAvatar);
Vue.component("BAvatarGroup", BAvatarGroup);
Vue.component("BCard", BCard);
Vue.component("BCardText", BCardText);
Vue.component("BLink", BLink);
Vue.component("BButton", BButton);
Vue.component("BForm", BForm);
Vue.component("BFormGroup", BFormGroup);
Vue.component("BFormInput", BFormInput);
Vue.component("BFormFile", BFormFile);
Vue.component("BFormTextarea", BFormTextarea);
Vue.component("BFormSelect", BFormSelect);
Vue.component("BFormCheckbox", BFormCheckbox);
Vue.component("BFormCheckboxGroup", BFormCheckboxGroup);
Vue.component("BModal", BModal);
Vue.component("VBModal", VBModal);
Vue.component("BCardBody", BCardBody);
Vue.component("BCardFooter", BCardFooter);
Vue.component("BRow", BRow);
Vue.component("BCol", BCol);
Vue.component("BInputGroup", BInputGroup);
Vue.component("BInputGroupAppend", BInputGroupAppend);
Vue.component("BDropdown", BDropdown);
Vue.component("BDropdownItem", BDropdownItem);
