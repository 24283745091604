import Vue from "vue";
import VueRouter from "vue-router";
import { isUserLoggedIn } from "@/auth/utils";
import hrm from "./routes/hrm";
import project_management from "./routes/project_management";
import timesheet from "./routes/timesheet";
import performance from "./routes/performance";
import inbox from "./routes/inbox";
// import recruitment from './routes/recruitment'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...hrm,
    ...project_management,
    ...timesheet,
    ...performance,
    ...inbox,
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/Dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/account-setting/AccountSetting.vue"),
      meta: {
        pageTitle: "Profile Setting",
        breadcrumb: [
          {
            text: "Profile Setting",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/knowledge-base",
      name: "knowledge-base",
      component: () => import("@/views/knowledge-base/KnowledgeBase.vue"),
      meta: {
        pageTitle: "Knowledge Base",
        breadcrumb: [
          {
            text: "Knowledge Base",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/knowledge-bases/category",
      name: "knowledge-bases/category",
      component: () =>
        import("@/views/knowledge-base/KnowledgeBaseCategory.vue"),
      meta: {
        pageTitle: "Category",
        breadcrumb: [
          {
            text: "Knowledge Base",
            active: false,
            to: "/knowledge-base",
          },
          {
            text: "Category",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/knowledge-bases/category/article",
      name: "knowledge-bases/category/article",
      component: () =>
        import("@/views/knowledge-base/KnowledgeBaseArticle.vue"),
      meta: {
        pageTitle: "Article",
        breadcrumb: [
          {
            text: "Knowledge Base",
            active: false,
            to: "/knowledge-base",
          },
          {
            text: "Category",
            active: false,
            to: "/knowledge-bases/category",
          },
          {
            text: "Article",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/permissions",
      name: "permissions",
      component: () => import("@/views/Permission.vue"),
      meta: {
        pageTitle: "Permissions",
        breadcrumb: [
          {
            text: "Permissions",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/calendar",
      name: "calendar",
      component: () => import("@/views/calendar/Calendar.vue"),
      meta: {
        pageTitle: "Calendar",
        breadcrumb: [
          {
            text: "Calendar",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/auth/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isUserLoggedIn()) {
      next({ name: "login" });
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
