export default [
  {
    path: '/hrm',
    name: 'hrm',
    component: () => import('@/views/modules/hrm/Dashboard.vue'),
    meta: {
      pageTitle: 'HRM',
      breadcrumb: [
        {
          text: 'HRM',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/hrm/employee',
    name: 'hrm/employee',
    component: () => import('@/views/modules/hrm/Employee.vue'),
    meta: {
      pageTitle: 'Employee',
      breadcrumb: [
        {
          text: 'HRM',
          to: '/hrm',
        },
        {
          text: 'Employee',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/hrm/employee/detail',
    name: 'hrm/employee/detail',
    component: () => import('@/views/modules/hrm/employee-detail/Detail.vue'),
    meta: {
      pageTitle: 'Detail Employee',
      breadcrumb: [
        {
          text: 'HRM',
          to: '/hrm',
        },
        {
          text: 'Detail Employee',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/hrm/employee/add',
    name: 'hrm/employee/add',
    component: () => import('@/views/modules/hrm/EmployeeAdd.vue'),
    meta: {
      layout: 'full',
    }
  },
  {
    path: '/hrm/contract',
    name: 'hrm/contract',
    component: () => import('@/views/modules/hrm/Contract.vue'),
    meta: {
      pageTitle: 'Contract',
      breadcrumb: [
        {
          text: 'HRM',
          to: '/hrm',
        },
        {
          text: 'Contract',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/hrm/department',
    name: 'hrm/department',
    component: () => import('@/views/modules/hrm/Department.vue'),
    meta: {
      pageTitle: 'Department',
      breadcrumb: [
        {
          text: 'HRM',
          to: '/hrm',
        },
        {
          text: 'Department',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/hrm/job-position',
    name: 'hrm/job-position',
    component: () => import('@/views/modules/hrm/JobPosition.vue'),
    meta: {
      pageTitle: 'Job Position',
      breadcrumb: [
        {
          text: 'HRM',
          to: '/hrm',
        },
        {
          text: 'Job Position',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/hrm/job-level',
    name: 'hrm/job-level',
    component: () => import('@/views/modules/hrm/JobLevel.vue'),
    meta: {
      pageTitle: 'Job Level',
      breadcrumb: [
        {
          text: 'HRM',
          to: '/hrm',
        },
        {
          text: 'Job Level',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/hrm/organization',
    name: 'hrm/organization',
    component: () => import('@/views/modules/hrm/OrganizationChart.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Organization Chart',
      breadcrumb: [
        {
          text: 'Organization Chart',
          to: '/hrm',
        },
        {
          text: 'Organization Chart',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  }
]