export default {
  // API
  BASE_API: "https://sw-api.hracademy.id/api",
  // HashidAs
  HASHID_SALT: "id-hra-2023",
  HASHID_SALT_LENGTH: 10,
  // Pusher
  PUSHER_BROADCASTER: "pusher",
  PUSHER_KEY: "26200f2ebb495ecc03f6",
  PUSHER_CLUSTER: "ap1",
  PUSHER_ENCRYPTED: true,
};
