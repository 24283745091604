export default [
  {
    path: '/timesheet',
    name: 'timesheet',
    component: () => import('@/views/modules/timesheet/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Attendance',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/timesheet/attendance',
    name: 'timesheet/attendance',
    component: () => import('@/views/modules/timesheet/Attendance.vue'),
    meta: {
      pageTitle: 'Attendances',
      breadcrumb: [
        {
          text: 'Timesheet',
          active: false,
        },
        {
          text: 'Attendance',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/timesheet/live-attendance',
    name: 'timesheet/live-attendance',
    component: () => import('@/views/modules/timesheet/LiveAttendance.vue'),
    meta: {
      pageTitle: 'Live Attendance',
      breadcrumb: [
        {
          text: 'Timesheet',
          active: false,
        },
        {
          text: 'Attendance',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/timesheet/leave',
    name: 'timesheet/leave',
    component: () => import('@/views/modules/timesheet/Leave.vue'),
    meta: {
      pageTitle: 'Leave',
      breadcrumb: [
        {
          text: 'Timesheet',
          active: false,
        },
        {
          text: 'Leave',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/timesheet/overtime',
    name: 'timesheet/overtime',
    component: () => import('@/views/modules/timesheet/Overtime.vue'),
    meta: {
      pageTitle: 'Overtime',
      breadcrumb: [
        {
          text: 'Timesheet',
          active: false,
        },
        {
          text: 'Overtime',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/timesheet/setting',
    name: 'timesheet/setting',
    component: () => import('@/views/modules/timesheet/settings/Setting.vue'),
    meta: {
      pageTitle: 'Leave Settings',
      breadcrumb: [
        {
          text: 'Timesheet',
          active: false,
        },
        {
          text: 'Leave Settings',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
]