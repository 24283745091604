export default [
  {
    path: '/performance',
    name: 'performance',
    component: () => import('@/views/modules/performance/Dashboard.vue'),
    meta: {
      pageTitle: 'Performance',
      breadcrumb: [
        {
          text: 'Performance',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/performance/goals-setting',
    name: 'performance/goals-setting',
    component: () => import('@/views/modules/performance/Goal.vue'),
    meta: {
      pageTitle: 'Goals',
      breadcrumb: [
        {
          text: 'Performance',
          to: '/performance',
        },
        {
          text: 'Goals Setting',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/performance/goals-mid-year',
    name: 'performance/goals-mid-year',
    component: () => import('@/views/modules/performance/GoalMidyear.vue'),
    meta: {
      pageTitle: 'Midyear Evaluations',
      breadcrumb: [
        {
          text: 'Performance',
          to: '/performance',
        },
        {
          text: 'Goals Mid Year',
          active: true,
        }
      ],
    },
  },
  {
    path: '/performance/goals-final-annual',
    name: 'performance/goals-final-annual',
    component: () => import('@/views/modules/performance/GoalFinalAnnual.vue'),
    meta: {
      pageTitle: 'Final Annual',
      breadcrumb: [
        {
          text: 'Performance',
          to: '/performance',
        },
        {
          text: 'Goals Final Annual',
          active: true,
        }
      ],
    }
  },
  {
    path: '/performance/reports',
    name: 'performance/reports',
    component: () => import('@/views/modules/performance/Report.vue'),
    meta: {
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Performance',
          to: '/performance',
        },
        {
          text: 'Reports',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/performance/goal',
    name: 'performance/goal',
    component: () => import('@/views/modules/performance/performance-review/Goal.vue'),
    props: true,
    meta: {
      layout: 'full',
      requiresAuth: true,
    },
  },
  {
    path: '/performance/goal/review',
    name: 'performance/goal/review',
    component: () => import('@/views/modules/performance/performance-review/GoalReview.vue'),
    props: true,
    meta: {
      layout: 'full',
      requiresAuth: true,
    }
  },
  {
    path: '/performance/goal/final_behavior',
    name: 'performance/goal/final_behavior',
    component: () => import('@/views/modules/performance/performance-review/GoalBehavior.vue'),
    props: true,
    meta: {
      layout: 'full',
      requiresAuth: true,
    }
  },
  {
    path: '/performance/goal/overall',
    name: 'performance/goal/overall',
    component: () => import('@/views/modules/performance/performance-review/GoalOverall.vue'),
    props: true,
    meta: {
      layout: 'full',
      requiresAuth: true,
    }
  },
  {
    path: '/performance/goal/summary',
    name: 'performance/goal/summary',
    component: () => import('@/views/modules/performance/performance-review/GoalsSummary.vue'),
    props: true,
    meta: {
      layout: 'full',
      requiresAuth: true,
    },
  },
  {
    path: '/performance/pmp',
    name: 'performance/pmp',
    component: () => import('@/views/modules/performance/Pmp.vue'),
    props: true,
    meta: {
      pageTitle: 'PME',
      breadcrumb: [
        {
          text: 'Performance',
          to: '/performance',
          active: false,
        },
        {
          text: 'PME',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/performance/talent_card',
    name: 'performance/talent-card',
    component: () => import('@/views/modules/performance/TalentCard.vue'),
    props: true,
    meta: {
      pageTitle: 'Talent Card',
      breadcrumb: [
        {
          text: 'Performance',
          to: '/performance',
          active: false,
        },
        {
          text: 'Talent Card',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/performance/key_succession',
    name: 'performance/key-succession',
    component: () => import('@/views/modules/performance/KeySuccession.vue'),
    props: true,
    meta: {
      pageTitle: 'Key Succession',
      breadcrumb: [
        {
          text: 'Performance',
          to: '/performance',
          active: false,
        },
        {
          text: 'Key Succession',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  }
]