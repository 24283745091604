import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueMobileDetection from "vue-mobile-detection";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import Hashids from "hashids";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import consts from "./constant";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import { VBModal } from "bootstrap-vue";

import { MonthPicker } from "vue-month-picker";
import { MonthPickerInput } from "vue-month-picker";

Vue.use(MonthPicker);
Vue.use(MonthPickerInput);

// import riple
import Ripple from "vue-ripple-directive";
import VueExcelXlsx from "vue-excel-xlsx";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueMobileDetection);
Vue.use(VueApexCharts);
Vue.use(VueExcelXlsx);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

// Ripple directive
Vue.directive("ripple", Ripple);
Vue.directive("b-modal", VBModal);

// laravel echo
import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: consts.PUSHER_BROADCASTER,
  key: consts.PUSHER_KEY,
  cluster: consts.PUSHER_CLUSTER,
  encrypted: consts.PUSHER_ENCRYPTED,
});

Vue.mixin({
  data() {
    return {
      permissions: [],
      roleUser: "",
    };
  },
  mounted() {
    if (this.getFromStorage("sw_auth_data")) {
      const permissions = JSON.parse(
        this.getFromStorage("sw_auth_data")
      ).permissions;
      permissions.forEach((item) => {
        this.permissions.push(item.name);
      });
      const roles = JSON.parse(this.getFromStorage("sw_auth_data")).roles;
      roles.forEach((item) => {
        this.roleUser = item.name;
        item.permissions.forEach((item) => {
          this.permissions.push(item.name);
        });
      });
    }
  },
  methods: {
    hasPermission(permission) {
      return this.permissions.includes(permission);
    },
    getAllPermissions() {
      return this.permissions;
    },
    getRole() {
      return this.roleUser;
    },
    capitalizeFirstLetter: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    eraseCookie(name) {
      document.cookie = name + "=; Max-Age=-99999999;";
    },
    async callApi(config = {}) {
      const defaultConfig = {
        method: "GET",
        baseURL: consts.BASE_API,
        headers: {
          Authorization: `Bearer ${this.getCookie("sw_auth_token")}`,
        },
        params: {},
        data: {},
        timeout: 10000,
      };
      await axios({ ...defaultConfig, ...config })
        // set callback
        .then((response) => {
          if (config.success) {
            config.success(response.data);
          }
        })

        // set error callback
        .catch((error) => {
          if (config.error) {
            config.error(error);
          }
        });
    },
    truncateText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "...";
      } else {
        return text;
      }
    },
    hashid(id) {
      const hashids = new Hashids(
        consts.HASHID_SALT,
        consts.HASHID_SALT_LENGTH
      );
      return hashids.encode(id);
    },
    getAuthId() {
      const authData = JSON.parse(this.getFromStorage("sw_auth_data"));
      return authData.id;
    },
    saveToStorage(key, value) {
      localStorage.setItem(key, value);
    },
    getFromStorage(key) {
      return localStorage.getItem(key);
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
