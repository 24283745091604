export default [
  {
    path: '/project',
    name: 'project',
    component: () => import('@/views/modules/project_management/Dashboard.vue'),
    meta: {
      pageTitle: 'Project Management',
      breadcrumb: [
        {
          text: 'Project Management',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/project/list',
    name: 'project/list',
    component: () => import('@/views/modules/project_management/Project.vue'),
    meta: {
      pageTitle: 'Projects',
      breadcrumb: [
        {
          text: 'Project Management',
          to: '/project',
          active: false,
        },
        {
          text: 'Project List',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/project/todo-list',
    name: 'project/todo-list',
    component: () => import('@/views/modules/project_management/TodoList.vue'),
    meta: {
      pageTitle: 'Tasks',
      breadcrumb: [
        {
          text: 'Project Management',
          to: '/project',
          active: false,
        }, {
          text: 'Tasks',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
  {
    path: '/project/milestone',
    name: 'project/milestone',
    component: () => import('@/views/modules/project_management/Milestone.vue'),
    meta: {
      pageTitle: 'Milestone',
      breadcrumb: [
        {
          text: 'Project Management',
          active: false,
        },
        {
          text: 'Milestone',
          active: true,
        }
      ],
      requiresAuth: true,
    }
  },
]